<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                    <md-icon>view_in_ar</md-icon>
                </div>
                <h4 class="title">Product</h4>
                </md-card-header>
                <md-card-content>
                <md-table v-model="contents" table-header-color="green">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Status">
                            <md-switch @change="changeStatus(item.number, item.isPublic)" v-model="item.isPublic" class="cast-controller"></md-switch>
                        </md-table-cell>
                        <md-table-cell md-label="Num">{{ item.number + 1 }}</md-table-cell>
                        <md-table-cell md-label="Thumbnail">
                            <img class="item-thumb" :src="item.thumbnailUrl" />
                        </md-table-cell>
                        <md-table-cell md-label="Data Type">{{ item.dataType }}</md-table-cell>
                        <md-table-cell md-label="Title">{{ item.dataTitle }}</md-table-cell>
                        <md-table-cell md-label="Language">{{
                            item.subLang && item.subLang.en ? 'KO/EN' : 'KO'
                        }}</md-table-cell>
                        <md-table-cell md-label="Edit">
                            <a href="javascript:void(0);" @click="goToEdit(item.number)" class="editBtn">Edit</a>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import api from "@/commons/apiUrl";
// import EditDocent from "@/components/Docent/EditDocent.vue";

export default {
    components: {
        // EditDocent
    },
    data() {
        return {
            contents: {},
            docent: {}
        }
    },
    async created() {
        const res = await axios.get(api.GET_ALL_CONTENTS(this.$route.params.exhibitionId),
            { 
                headers: { 
                    authorization: this.$store.getters.getToken,
                }
            }
        );
        if(!res.data.success) {
            return;
        }

        this.contents = res.data.data;
    },
    methods: {
        goToEdit(number) {
            this.$router.push(`/showroom/${this.$route.params.exhibitionId}/edit/product/${number}`);
        },
        async changeStatus(number, isPublic) {
            const data = new FormData();
            
            data.append("isPublic", isPublic);

            const res = await axios.put(api.UPDATE_CONTENT_STATUS(this.$route.params.exhibitionId, number),
                data,
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                }
            );

            if(!res.data.success) {
                this.$store.dispatch("alerts/error", "콘텐츠 상태 변경 실패");

                return;
            }

            this.$store.dispatch("alerts/success", "콘텐츠 상태가 변경되었습니다.");
        }
    }
}
</script>

<style scoped>
.item-thumb {
    max-width: 50px;
}
.editBtn {
    font-weight: bold;
}
</style>

<style>
.md-table[table-header-color=green] .md-table-head .md-table-head-label {
    color: #38ac90 !important;
}
.md-theme-default a:not(.md-button) {
    color: #38ac90;
    color: var(--md-theme-default-primary-on-background, #38ac90);
}
</style>